@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    left: 50vw;
    top: 60vh;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes unrotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@font-face {
  font-family: "Jura";
  src: local("Jura"), url(./fonts/Jura-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "JuraHeading";
  font-weight: 700;
  src: local("Jura"), url(./fonts/Jura-Bold.ttf) format("truetype");
}
