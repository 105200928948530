.NetworkStatus {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    height: 50px;
}

.NetworkStatus .error-banner {
    background-color: red;
    text-align: center;
    line-height: 50px;
    height: 50px;
}

.NetworkStatus .error-text {
    text-align: center;
    font-weight: bold;
    color: black;
}