@keyframes slide {
  from {
    margin-left: -200px;
    opacity: 0%;
  }
  50% {
    opacity: 100%;
  }
  to {
    margin-left: 200px;
    opacity: 0%;
  }
}

@keyframes pulse {
  from {
    opacity: 0%;
  }
  20% {
    opacity: 100%;
  }
  50% {
    opacity: 100%;
  }
  to {
    opacity: 0%;
  }
}

.blackhole1 {
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.badge {
  animation-name: pulse;
  animation-duration: 1s;
  animation-timing-function: step-end;
  animation-iteration-count: infinite;
}
